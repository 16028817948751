import { Col, Row } from "reactstrap"

import ContractsListStyle from "./style"
import EntityCard from "../EntityCard/EntityCard.component"
import { Entity } from "../../../redux/auth/auth.reducer"

type ContractsListType = {
  id: string
  entities: Entity[]
}

const ContractsList = ({ id, entities }: ContractsListType) => (
  <ContractsListStyle id={id}>
    <h1 className="subtitle">Operazioni disponibili</h1>
      <div className="contracts-container">
        <Row>
          {entities.map((entity, i) => (
            <Col md={6} lg={3} key={i}>
              <EntityCard 
                entity={entity}
              />
            </Col>
          ))}
        </Row>
      </div>
  </ContractsListStyle>
)

export default ContractsList