import { Card } from "reactstrap"
import styled from "styled-components"

const EntityCardStyle = styled(Card)`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: 1px solid #ccc;
  margin-bottom: 1.5rem;
  height: 24rem; 

  .card-header {
    font-size: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: #0e3651;
    border: none;
  }


  .card-body {
    padding: 1rem 1rem;
  }

  .card-title {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-weight: 600;
  }

  .card-text {
    font-size: .9rem;
  }

  .card-footer {
    background-color: transparent;
    border: none;
    display: flex;
    padding: 1rem;
    align-items: center;
  }
`

export default EntityCardStyle