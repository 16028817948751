import { useSelector } from "react-redux"
import { Container, Row, Col } from "reactstrap"

import LandingPageStyle from "./style"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"
import ContractsList from "./ContractsList/ContractsList.component"
import { 
  selectUserAvailableContracts, 
  selectUsername,
} from "../../redux/auth/auth.selectors"

const LandingPage = () => {
  const username = useSelector(selectUsername)
  const customerEnties = useSelector(selectUserAvailableContracts)

  return (
    <DefaultPageContainer>
      <LandingPageStyle>
        <header>
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <p className="lead">Benvenuto <strong>{username}</strong></p>
              </Col>
            </Row>
          </Container>
        </header>
        <main>
          <Container>
          {!!customerEnties.length &&
            <ContractsList 
              id="new-clients"
              entities={customerEnties} 
            />
          }
          </Container>
        </main>
      </LandingPageStyle>
    </DefaultPageContainer>
  )
}

export default LandingPage