import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Collapse, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import HeaderStyle from "./style"
import { ReactComponent as Logo } from '../../assets/svg/emvelop-logo-white.svg'
import UserNav from '../NavMenu/UserNav/UserNav.component'
import { selectCurrentUser, selectUserAvailableContracts, selectUsername } from '../../redux/auth/auth.selectors'
import { useAppSelector } from '../../redux/hooks'
import { useLogOutMutation } from '../../redux/auth/auth.api'
import { handleMenuClick } from '../NavMenu/utils'
import ContractsMenu from '../NavMenu/ContractsMenu/ContractsMenu.component'

const Header = () => {
  const userLoggedIn = useSelector(selectCurrentUser)
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const availableContracts = useAppSelector(selectUserAvailableContracts)
  const username = useAppSelector(selectUsername)
  const [logout] = useLogOutMutation()  

  const handleLogout = useCallback(() => {
    logout(undefined)
  }, [logout])

  return (
    <HeaderStyle id="navigation-header">
      <Navbar className="align-items-center" dark expand="lg" container={true}>
        <NavLink className="navbar-brand" end to="/">
          <Logo />
        </NavLink>
        {userLoggedIn &&
          <>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav 
                className="ms-auto" 
                navbar 
                onClick={e => handleMenuClick(e.target as HTMLElement, toggle)}
              >
                <NavItem>
                  <NavLink 
                    end
                    className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
                    to="/"
                  >
                    <FontAwesomeIcon icon={['fas', 'home']} />{' '}
                    home
                  </NavLink>
                </NavItem>
                <UserNav />
                {availableContracts.map((item, i) => 
                  <ContractsMenu
                    contract={item}
                    key={i}
                  />
                )}
                <NavItem>
                  <Button
                    title="log out" 
                    color="link" 
                    className="nav-link active-link" 
                    onClick={handleLogout}
                  >
                    <FontAwesomeIcon className="me-1" icon="sign-out-alt" />
                    <span className="username">
                      {username}
                    </span>
                  </Button>
                </NavItem>
              </Nav>
            </Collapse>
          </>
        }
      </Navbar>
    </HeaderStyle>
  )
}

export default Header