import styled from "styled-components"

const ContractsListStyle = styled.section`
  margin-bottom: 5rem;

  .subtitle {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkGrey};
  }

  .title {
    color: ${({ theme }) => theme.colors.green};
    font-size: 26px;
    font-weight: 700;
  }

  .contracts-container {
    margin-top: 1.5rem;
    padding-top: 1rem;
    position: relative;
  }
`

export default ContractsListStyle