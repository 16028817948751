import { createGlobalStyle } from "styled-components"

const GenericStyles = createGlobalStyle`
  body {
    font-family: "Assistant", "Roboto", sans-serif;
  }
  
  .spin-animation {
    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  b, strong {
    font-weight: bold;
  }

  .fa-arrows-rotate {
    color: ${({ theme }) => theme.colors.blue};
  }
  .fa-circle-xmark {
    color: ${({ theme }) => theme.colors.red};
  }
  .fa-door-open {
    color: ${({ theme }) => theme.colors.violet};
  }
  .fa-signature {
    color: ${({ theme }) => theme.colors.orange};
  }
  .fa-gears {
    color: ${({ theme }) => theme.colors.ligthIndaco};
  }
  .fa-clipboard-check {
    color: ${({ theme }) => theme.colors.blue};
  }
  .fa-circle-check {
    color: ${({ theme }) => theme.colors.green};
  }
  .fa-hand {
    color: ${({ theme }) => theme.colors.red};
  }
  .fa-circle-half-stroke {
    color: ${({ theme }) => theme.colors.yellow};
  }
`

export default GenericStyles