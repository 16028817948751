import { IconName } from "@fortawesome/fontawesome-svg-core"
import { createReducer } from "@reduxjs/toolkit"

import { authApi } from "./auth.api"
import { AsyncStatusEnum, LoginSteps } from "./auth.utils"
import { Permissions } from "../api/types"

export type CurrentUser = {
  code: string
  email: string
  id: string
  insertData: Date
  links: Permissions
  name: string
  role: string
}

export type Entity = {
  contractCode: string
  type: string
  entityName: string
  name: string
  description: string
  icon: IconName
  active: boolean
  category: "newCustomer" | "ongoingCustomer"
  position: number
  inEvidenceUntil?: Date
}

export type AuthState = {
  fetchStatus: [string | null, string]
  submitStatus: [string | null, string]
  currentUser: CurrentUser | null
  entities: Entity[] | null
  loginStep: number
  error: { [key: string]: any } | null
  utilityModal: {
    visibility: boolean
    modalTitle: string
    modalDescription: string
    modalIcon: string
  }
}

const INITIAL_STATE: AuthState = {
  fetchStatus: [null, AsyncStatusEnum.IDLE],
  submitStatus: [null, AsyncStatusEnum.IDLE],
  currentUser: null,
  entities: null,
  loginStep: LoginSteps.USER_EMAIL_STEP,
  error: null,
  utilityModal: {
    visibility: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  }
}

const authReducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addMatcher(authApi.endpoints.checkUserEmail.matchFulfilled, (state, action) => {
      if(action.payload.mustChangePassword === false) {
        state.loginStep = LoginSteps.USER_PASSWORD_STEP
      }
      else if(action.payload.mustChangePassword === true) {
        state.utilityModal.visibility = true
        state.utilityModal.modalTitle = "Reset password"
        state.utilityModal.modalIcon = 'exclamation-triangle'
        state.utilityModal.modalDescription = action.payload.message
      }
    })
    .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      if(action.payload) {
        state.currentUser = action.payload.currentUser
        state.entities = action.payload.entities
      }
      state.submitStatus = ['userLogin', AsyncStatusEnum.SUCCESS]
    })
    .addMatcher(authApi.endpoints.checkUserSession.matchPending, (state, action) => {
      state.fetchStatus = [action.meta.arg.endpointName, AsyncStatusEnum.LOADING]
    })
    .addMatcher(authApi.endpoints.checkUserSession.matchFulfilled, (state, action) => {
      state.fetchStatus = [action.meta.arg.endpointName, AsyncStatusEnum.SUCCESS]
      if(action.payload) {
        state.currentUser = action.payload.currentUser
        state.entities = action.payload.entities
      }
    })
    .addMatcher(authApi.endpoints.checkUserSession.matchRejected, (state, action) => {
      if(action.payload) {
        state.submitStatus = [action.meta.arg.endpointName, AsyncStatusEnum.FAILURE]
      }
    })
    .addMatcher(authApi.endpoints.logOut.matchFulfilled, (state, action) => {
      return {
        ...INITIAL_STATE,
        submitStatus: ['userLogout', AsyncStatusEnum.SUCCESS]
      }
    })
})

export default authReducer