import { createGlobalStyle } from "styled-components"

const ButtonStyles = createGlobalStyle`
  button, .btn {
    font-weight: 700;
    font-size: .85rem;

    svg[class*="fa-"] {
      color: inherit !important;
    }

    &.ab-button {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.blue};

      &:hover,
      &:active {
        background-color: ${({ theme }) => theme.colors.darkGrey};
      }
    }
  }
`

export default ButtonStyles