import { createGlobalStyle } from "styled-components"

const FormStyles = createGlobalStyle`
  form {
    button[type="submit"] {
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.white};
    }

    .grouped {
      border-radius: .75rem;
      margin-bottom: 1.5rem;

      .grouped-header {
        border: 1px solid ${({ theme }) => theme.colors.grey};
        padding: .5rem .75rem;
        border-radius: .75rem .75rem 0 0;
        background-color: ${({ theme }) => theme.colors.dirtWhite};
        color: ${({ theme }) => theme.colors.darkGrey};
      }

      .grouped-body {
        border-radius: 0 0 .75rem .75rem;
        border: 1px solid ${({ theme }) => theme.colors.grey};
        border-top: none;
        padding: .5rem .75rem;
      }
    }

    label {
      color: ${({ theme }) => theme.colors.blue};
      font-size: .8rem;
      font-weight: 600;
      margin-bottom: .15rem;
    }

    legend {
      font-size: .8rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.darkGrey};
    }

    .text-muted {
      font-size: 70%;
    }

    .invalid-feedback {
      font-size: .8rem;
    }

    .form-control {
      padding: .5rem .75rem;
      border-radius: 4px;
    }

    .custom-select__control,
    .form-control {
      font-size: .85rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.darkGrey};
      border-color: ${({ theme }) => theme.colors.lightBlue};

      &[readonly] {
        background-color: ${({ theme }) => theme.colors.dirtWhite};
      }
      
      ::placeholder {
        color: #ddd;
      }
    }

    .custom-select__placeholder {
      color: #ddd;
    }

    .form-control.text-uppercase {
      ::placeholder {
        text-transform: none;
      }
    }

    .custom-select__option {
      font-size: .8rem;
      padding: 8px;
    }

    .penso-in-verde-icon {
      width: 16px;
      fill: ${({ theme }) => theme.colors.green};
      margin-right: .25rem;
    }

  }

  input.phone-number::-webkit-outer-spin-button,
  input.phone-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input.phone-number[type=number] {
    -moz-appearance: textfield;
  }
`

export default FormStyles