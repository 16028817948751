import styled from "styled-components"

const LandingPageStyle = styled.div`
  header {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    padding: 1rem;
    
    .lead {
      font-size: .85rem;
      margin: 0;
    }

    .other-links-container {
      display: flex;
      justify-content: flex-end;
    }

    .other-links {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.white};
      border-radius: 0.25rem;
      color: ${({ theme }) => theme.colors.white};
      font-size: .85rem;
      font-weight: 700;
      padding: 0.375rem 0.75rem;
      text-decoration: none;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    }
  }

  main {
    padding: 2.5rem 0;

    .main-title {
      color: ${({ theme }) => theme.colors.blue};
      font-size: 34px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
`

export default LandingPageStyle