import styled from "styled-components"

const LoginEmailFormStyle = styled.div`
  .login-icon {
    display: flex;
    margin: 0 auto 1.5rem;
    color: #337ab7;
    font-size: 4rem;
    justify-content: center;
  }

  .lead {
    line-height: 1.2;
    font-weight: 600;
    font-size: 1rem;
  }
`

export default LoginEmailFormStyle