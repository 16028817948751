import EntityCardStyle from "./style"
import { Entity } from "../../../redux/auth/auth.reducer"
import { selectCurrentUser } from "../../../redux/auth/auth.selectors"
import { useAppSelector } from "../../../redux/hooks"
import { CardBody, CardFooter, CardHeader, CardText, CardTitle } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CanI from "../../../components/auth/CanI.component"
import { Link } from "react-router-dom"
import { getEntityPath, getEntityPluralName } from "../../../redux/contract/contract.utils"

type EntityCardProps = {
  entity: Entity
}

const EntityCard = ({
  entity
}: EntityCardProps) => {
  const { links } = useAppSelector(selectCurrentUser)!
  const { name, description, icon, entityName } = entity

  return (
    <EntityCardStyle>
      <CardHeader>
        <FontAwesomeIcon icon={icon} />
      </CardHeader>
      <CardBody>
        <CardTitle tag="h5">
          {name}
        </CardTitle>
        <CardText>
          {description}
        </CardText>
      </CardBody>
      <CardFooter>
        <CanI doWhat={"GET"} withPermissions={links} entityName={getEntityPluralName(entityName!)}>
          {() => (
            <Link className="btn btn-outline-secondary outline" to={`/${getEntityPath(entityName)}/all`}>
              <FontAwesomeIcon icon="list" className="me-1" />{' '}
              Vedi tutti
            </Link>
          )}
        </CanI>
        <CanI doWhat={"CREATE"} withPermissions={links} entityName={entityName}>
          {() => (
            <Link 
              className="btn btn-secondary ms-2" 
              to={`/${getEntityPath(entityName)}`}
            >
              <FontAwesomeIcon icon="plus-circle" className="me-1" />{' '}
              Crea nuovo
            </Link>
          )}
        </CanI>
      </CardFooter>
    </EntityCardStyle>
  )
}

export default EntityCard