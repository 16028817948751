import { createGlobalStyle } from "styled-components"

const ModalStyles = createGlobalStyle`
  .modal-header {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};

    svg {
      color: inherit !important;
    }
  }

  .entity-info-modal {
    .icon {
      text-align: center;
      font-size: 30px;
      color: ${({ theme }) => theme.colors.yellow};
    }

    .title {
      margin: 1.5rem auto;
      color: ${({ theme }) => theme.colors.blue};
      text-transform: uppercase;
      font-size: 20px;
      font-weight: bold;
      text-align: center;

      svg {
        margin-right: .5rem;
      }
    }

    .desc {
      font-size: 16px;
      color: #222529;
      padding: 0 1rem;
      margin-bottom: 1.5rem;
    }

    .modal-footer {
      justify-content: center;

      button {
        color: ${({ theme }) => theme.colors.blue};
        border-color: ${({ theme }) => theme.colors.blue};
        font-size: 14px;
        font-weight: bold;

        &:hover {
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.blue};
        }
      }
    }
  }
`

export default ModalStyles